import React from 'react'


const Feedback = (props) => (
  <iframe src="https://forms.monday.com/forms/embed/afd004c9495060caf0f1313de599b563?r=use1"
          width="100%" height="1280px"
          style={{border: 0, boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)'}}/>
);

export default Feedback;
