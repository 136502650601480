{
  "id": "maize",
  "name": "Gramene Maize",
  "version": "v5",
  "ga": "G-Y7ZYG1R8QT",
  "description": "Comparative plant genomics focused on maize varieties",
  "url": "https://maize-pangenome.gramene.org",
  "ensemblURL": "https://maize-pangenome-ensembl.gramene.org",
  "ensemblSite": "https://maize-pangenome-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-108",
  "grameneData": "https://data.gramene.org/maize_v5",
  "targetTaxonId": 4577,
  "portals": ['browser','reactome','atlas','blast','pansites','curated','maizedownloads','tutorials','maizegdb'],
  "portals2": ['pansites','browser','reactome','blast','atlas','tutorials','maizedownloads'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "date": "2025-03-03",
  "examples": [
    {
      "title": 'lox9 Variant Image',
      "description": "Explore genetic variation and alt-allele consequences from the Variant Effect Predictor (VEP).",
      "imgSrc": "static/images/welcome/maize/lox9_Variant_Image.png",
      "link": 'https://maize-pangenome-ensembl.gramene.org/Zea_maysb73/Gene/Variation_Gene/Image?db=core;g=Zm00001eb005920;r=1:16843538-16850405'
    },
    {
      "title": "lox9 Pathway Diagram",
      "description": "Jasmonic Acid Biosynthesis pathway at Plant Reactome.",
      "imgSrc": "static/images/welcome/maize/lox9_Pathway_Diagram.png",
      "link": 'https://plantreactome.gramene.org/PathwayBrowser/#/R-ZMA-1119332&SEL=R-ZMA-1119658'
    },
    {
      "title": "MaizeCODE tracks",
      "description": "Expression, Methylation, Acetylation, RAMPAGE, ChIP-seq tracks from  Cahn, J., Regulski, M., Lynn, J. et al. MaizeCODE reveals bi-directionally expressed enhancers that harbor molecular signatures of maize domestication. Nat Commun 15, 10854 (2024). https://doi.org/10.1038/s41467-024-55195-w",
      "imgSrc": "static/images/welcome/maize/maizeCODE.png",
      "link": "https://maize-pangenome-ensembl.gramene.org/Zea_maysb73/Location/View?db=;g=Zm00001eb054440;r=1:272330147-272333065;t=Zm00001eb054440_T001"
    }
  ],
  details: {
    sequences: true,
    VEP: true,
    location: true,
    expression: true,
    homology: true,
    pathways: true,
    pubs: true,
    xrefs: true
  },
  "panSite" : {
    "sorghum_bicolor" : {
      "url": "https://sorghumbase.org/genes?idList=",
      "name": "SorghumBase",
      "svg": "./static/images/sorghum_logo.svg"
    },
    vitis_vinifera : {
      url: "https://vitis.gramene.org?idList=",
      name: "Gramene Grapevine",
      svg: "./static/images/grapevine_logo.svg"
    },
    oryza_sativa : {
      url: "https://oryza.gramene.org?idList=",
      name: "Gramene Oryza",
      svg: "./static/images/oryza_logo.svg"
    }
  },
  "slides": [],
  "oldslides": [
    {
      "img": "static/images/welcome/maize/NAM.png",
      "label": "NAM founder inbreds",
      "text": "This site includes full genome sequences of the NAM founder lines and comparative genomic analyses of protein-coding gene families.",
      "credit": "McMullen et al. Science (2009)"
    },
    {
      "img": "static/images/welcome/maize/lox-suggestions.png",
      "label": "Type-ahead search",
      "text": "Click in the search box and start typing to find matching terms. Select a suggested term to define a search filter. Add more filters at any time by searching for another search term."
    },
    {
      "img": "static/images/welcome/maize/lox-homologs.png",
      "label": "Search results for homologs of lox1",
      "text": "The taxo(ge)nomic distribution of search results highlights positions of genes on the assembled chromosomes. Drag-select regions to refine the search."
    },
    {
      "img": "static/images/welcome/maize/lox-expression.png",
      "label": "Expression: EBI Atlas view",
      "text": "This view depicts baseline gene expression data for a whole plant (top anatogram on the left of the results box) and for the plant’s reproductive organs (bottom anatogram). Experiments are organized by study on the left side, and by tissue or developmental stage on the top of the graph."
    },
    {
      "img": "static/images/welcome/maize/lox-align-overview.png",
      "label": "Homology: Alignment overview",
      "text": "The homology tab displays an interactive gene tree visualization. The alignment overview mode shows a zoomed-out view of the multiple sequence alignment, coded by Interpro domain."
    },
    {
      "img": "static/images/welcome/maize/lox-malign.png",
      "label": "Homology: Multiple sequence alignment",
      "text": "The multiple sequence alignment mode lets you zoom in to explore differences among the sequences in a gene family."
    },
    {
      "img": "static/images/welcome/maize/lox-neighborhood.png",
      "label": "Homology: Neighborhood Conservation view",
      "text": "This view presents the target gene in the middle with a vertical red line connecting the homologs from each genome in the tree plus 10 flanking genes upstream and downstream color-coded by gene family. This allows you to identify structural variants and Presence/Absence variants in the region in question."
    },
    {
      "img": "static/images/welcome/maize/lox-pathways.png",
      "label": "Pathways: Plant Reactome view",
      "text": "From the literature, we know that lox9 is part of the jasmonic acid (JA) biosynthesis pathway. Accordingly, the Pathways panel shows that this gene product catalyzes a reaction in two pathways, JA biosynthesis, and 13-LOX/13-HPL."
    }
  ]
}
