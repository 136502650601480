var ENA = {
  label: 'ENA',
  url: function(id) {
    return 'http://www.ebi.ac.uk/ena/data/view/'+id;
  }
};
var thalemine = {
  label: 'thalemine',
  url: function(id) {
    return 'https://bar.utoronto.ca/thalemine/keywordSearchResults.do?searchTerm='+id+'&searchSubmit=search';
  }
};
var TAIR = {
  label: 'TAIR',
  url: function(id) {
    return `https://www.arabidopsis.org/results?mainType=general&category=genes&searchText=${id}`
  }
};
module.exports = {
  TAIR_LOCUS: TAIR,
  ARAPORT_GENE: thalemine,
  PanOryza: {
    label: 'PanOryza pan gene',
    url: function(id) {
      return `?fq_field=PanOryza__xrefs&fq_value=${id}&category=PanOryza pan gene&name=${id}`
    }
  },
  SB_PanGene: {
    label: 'SorghumBase pan gene',
    url: function(id) {
      return `?fq_field=SB_PanGene__xrefs&fq_value=${id}&category=SorghumBase pan gene&name=${id}`
    }
  },
  'Pan-Zea': {
    label: "Maize pan gene",
    url: function(id) {
      return `?fq_field=Pan-Zea__xrefs&fq_value=${id}&category=Maize pan gene&name=${id}`
    }
  },
  UniGene: {
    label: 'UniGene',
    url: function(id) {
      return 'http://www.ncbi.nlm.nih.gov/unigene/?term='+id;
    }
  },
  UniParc: {
    label: 'UniParc',
    url: function(id) {
      return 'http://www.uniprot.org/uniparc/'+id;
    }
  },
  'Uniprot/SPTREMBL': {
    label: 'UniProt',
    url: function(id) {
      return 'http://www.uniprot.org/uniprot/'+id;
    }
  },
  'Uniprot/SWISSPROT': {
    label: 'UniProt',
    url: function(id) {
      return 'http://www.uniprot.org/uniprot/'+id;
    }
  },
  EMBL: ENA,
  protein_id: {
    label: 'ENA proteins',
    url: function(id) {
      return 'http://www.ebi.ac.uk/ena/data/view/'+id;
    }
  },
  KEGG_Enzyme: {
    label: 'KEGG Enzyme',
    url: function(id) {
      return 'http://www.genome.jp/dbget-bin/www_bget?'+id;
    }
  },
  UniPathway: {
    label: 'UniPathway',
    url: function(id) {
      return 'http://www.grenoble.prabi.fr/obiwarehouse/unipathway/upa?upid='+id;
    }
  },
  MetaCyc: {
    label: 'MetaCyc',
    url: function(id) {
      return 'http://metacyc.org/META/NEW-IMAGE?type=NIL&object='+id;
    }
  },
  MEROPS: {
    label: 'MEROPS',
    url: function(id) {
      return 'http://merops.sanger.ac.uk/cgi-bin/pepsum?id='+id;
    }
  },
  PDB: {
    label: 'PDB',
    url: function(id) {
      return 'http://www.rcsb.org/pdb/explore.do?structureId='+id;
    }
  },
  GOA: {
    label: 'QuickGO',
    url: function(id) {
      return 'http://www.ebi.ac.uk/QuickGO/GProtein?ac='+id;
    }
  },
  IntEnz: {
    label: 'IntEnz',
    url: function(id) {
      return 'http://www.ebi.ac.uk/intenz/query?cmd=SearchEC&ec='+id;
    }
  },
  reactions: {
    label: 'Plant Reactome',
    url: function(id) {
      return 'http://plantreactome.gramene.org/content/detail/'+id;
    }
  },
	NASC_GENE_ID: {
		label: 'NASC',
		url: function(id) {
			return 'http://arabidopsis.info/MultiResult?agi_code='+id;
		}
	},
	KNETMINER_ARA: {
		label: 'KnetMiner',
		url: function(id) {
			return 'https://knetminer.rothamsted.ac.uk/araknet/genepage?list='+id;
		}
	},
	KNETMINER_RICE: {
		label: 'KnetMiner',
		url: function(id) {
			return 'https://knetminer.rothamsted.ac.uk/riceknet/genepage?list='+id;
		}
	},
	KNETMINER_WHEAT: {
		label: 'KnetMiner',
		url: function(id) {
			return 'https://knetminer.rothamsted.ac.uk/wheatknet/genepage?list='+id;
		}
	},
  PUBMED: {
    label: 'PubMed',
    url: function(id) {
      return 'https://pubmed.ncbi.nlm.nih.gov/'+id;
    }
  }
};
